import { render, staticRenderFns } from "./adminManage.vue?vue&type=template&id=079d4066&scoped=true&"
import script from "./adminManage.vue?vue&type=script&lang=js&"
export * from "./adminManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "079d4066",
  null
  
)

export default component.exports