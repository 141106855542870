<!--
 * @Author: your name
 * @Date: 2020-11-27 09:48:29
 * @LastEditTime: 2020-11-27 13:57:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\userManage\userManage.vue
-->
<template>
    <div>
        <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">{{$t('user.id')}}</span>
                <el-input v-model="email" :placeholder="$t('common.info.pl-insert-content')" clearable></el-input>
            </div>
            <div style="margin-left:10px;">
                <el-button type="primary" @click="query" style='margin-left:10px;'>{{$t('common.button.query')}}</el-button>
            </div>
        </div>
        <div class="button-box">
            <!--<el-button type="primary"  icon='el-icon-unlock' style='margin-right:10px;' @click='dialogVisible = true'>添加管理员</el-button>
            <el-popconfirm
                title="确定要批量删除吗？"
                @onConfirm='deleteBatch()'

            >
                <el-button type="primary" slot="reference" icon='el-icon-delete' >删除</el-button>
            </el-popconfirm>-->
        </div>
        <baseTable v-loading="loading"  row-key='sid' :data="dataRows" border  stripe @selection-change="handleSelectionChange($event)"  :column='column' :select='true'>
            
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
        
        <el-dialog
        title="添加管理员"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal='false'
        >
            <el-row :gutter="20">
                <el-col :span="16" :offset="3">
                    <el-form ref="form" :model="form" label-width="80px" >
                        <el-form-item label="账户" prop="loginName" :rules="[{ required: true, message: '不能为空'}]">
                            <el-input type="text" v-model="form.loginName"></el-input>
                        </el-form-item>
                        <el-form-item label="姓名" prop="userName" :rules="[{ required: true, message: '不能为空'}]">
                            <el-input type="text" v-model="form.loginName"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '不能为空'}]">
                            <el-input type="text" v-model="form.password"></el-input>
                        </el-form-item>
                        <el-form-item label="权限" prop="scope" :rules="[{ required: true, message: '不能为空'}]">
                            <el-checkbox-group v-model="form.scope">
                                <el-checkbox label="复选框 A">逆变器管理</el-checkbox>
                                <el-checkbox label="复选框 B">光伏模块管理 </el-checkbox>
                                <el-checkbox label="复选框 C">区域管理</el-checkbox>
                                <el-checkbox label="复选框 C">用户管理</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
        </el-dialog>
        <el-dialog
        title="修改管理员"
        :visible.sync="dialogVisibleEdit"
        width="30%"
        :close-on-click-modal='false'
        >
            <el-row :gutter="20">
                <el-col :span="16" :offset="1">
                    <el-form ref="formEdit" :model="formEdit" label-width="80px" >
                        <el-form-item label="账户" prop="loginName" :rules="[{ required: true, message: '不能为空'}]">
                            <el-input type="text" v-model="formEdit.loginName"></el-input>
                        </el-form-item>
                        <el-form-item label="姓名" prop="userName" :rules="[{ required: true, message: '不能为空'}]">
                            <el-input type="text" v-model="formEdit.loginName"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '不能为空'}]">
                            <el-input type="text" v-model="formEdit.password"></el-input>
                        </el-form-item>
                        <el-form-item label="权限" prop="scope" :rules="[{ required: true, message: '不能为空'}]">
                            <el-checkbox-group v-model="formEdit.scope">
                                <el-checkbox label="复选框 A">1</el-checkbox>
                                <el-checkbox label="复选框 B">2</el-checkbox>
                                <el-checkbox label="复选框 C">3</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleEdit = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisibleEdit = false">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
export default {
    components: {
        baseTable
    },
    props: {

    },
    data() {
        return {
            email:'',
            begin:"",
            end:"",
            pickerOptions:{ //禁用今天之后的时间
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            loading:false,
            dialogVisible:false,
            dialogVisibleEdit:false,
            dataRows:[],
            size:10,
            current:1,
            total:1,
            column:[{
                title: this.$t('system.user.name'),
                value: 'name',
            },{
                title: this.$t('user.id'),
                value: 'email',
            },{
                title: this.$t('system.user.login-time'),
                value: 'loginTime',
            }],
            selectDataList:[],
            form:{
                loginName:'',
                userName:'',
                password:'',
                scope:[],
            },
            formEdit:{
                loginName:'',
                userName:'',
                password:'',
                scope:[],
            }

        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.systemTUserGetAdminPage()
    },
    methods: {

        async systemTUserGetAdminPage(){
            this.loading = true
            let res = await this.API.systemTUserGetAdminPage({
                email: this.email,
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false
        },


        changeDateTime(){
            
        },
        query(){
            this.current = 1
            this.systemTUserGetAdminPage()
        },
        handleSelectionChange(val){
          this.selectDataList=val.map( item=>{
            return item.sid
          })
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.systemTUserGetAdminPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.systemTUserGetAdminPage()
        },
    },
};
</script>

<style  lang="less" scoped>

</style>
